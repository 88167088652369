require('./bootstrap');
import { lambert93toWGPS } from "./pages/helpers";

(($) => {
    window.ajaxRequests = 0;
    // Default Ajax Configuration
    let body = $('body');
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function (e, data) {
            if (
                data.url !== '/datatable/language' &&
                data.url !== '/api/v1/codebarres/datatables' &&
                !data.url.match(/\/api\/v1\/dashboard\/\w+/)
            ) {
                let queryString = data.url.split('?')[1];
                if (queryString) {
                    queryString = queryString.split('=');
                    if (queryString[0] == 'spinner' && queryString[1] == 'false') {
                        return;
                    }
                }
                ajaxRequests++;
                addLoader(ajaxRequests);
            }
            //$('button:not(:disabled)').addClass('processing').prop('disabled', true);
        }
    });
    $(document).ajaxComplete(function (e, xhr, settings) {
        if (settings.url !== '/json/jquery.dataTables.fr.l10n.json' && settings.url !== '/datatable/language') {
            ajaxRequests--;
            removeLoader(ajaxRequests);
        }
        //$('button.processing').removeClass('processing').prop('disabled', false);
    });

    $(document).ajaxStop(function () {
        for (let i = ajaxRequests; i > 0; i--) {
            ajaxRequests--;
            removeLoader(ajaxRequests);
        }
    });

    $(document).on('change', '.custom-file-input', function () {
        const inp = $(this.files);

        if (inp.length) {
            $(this).siblings(".custom-file-label").addClass("selected").html(inp.length + " fichier" + (inp.length > 1 ? "s" : ""));
        } else {
            $(this).siblings(".custom-file-label").removeClass("selected").html("Choisir un fichier");
        }
    });

    window.addLoader = function (id) {
        if (id === 1) {
            let loader = `<div class="loader-wrapper" data-id="${id}">
                            <div class="loader-backdrop"></div>
                            <div class="loader-container">
                                <div class="loader"></div>
                            </div>
                          </div>`;
            body.addClass('overflow-hidden');
            body.append(loader);
        } else if (id > 1) {
            body.find('.loader-wrapper').data('id', id);
        }
    };

    window.removeLoader = function (id) {
        if (id > 1) {
            body.find('.loader-wrapper').data('id', id);
        } else if (id === 0) {
            body.removeClass('overflow-hidden');
            body.find('.loader-wrapper').remove();
        }
    };

    let willReload = false;
    $(document).ajaxError(function (event, jqXHR, settings, thrownError) {
        try {
            if (jqXHR.status === 401 && jqXHR.responseJSON.message.includes('Unauthenticated', 0)) {
                if (!willReload) {
                    willReload = true;
                    Swal.fire({
                        // position: 'top-end',
                        type: 'error',
                        icon: 'warning',
                        title: '<p class="text-center">Votre session a expirée<br/>Vous devez se reconnecter</p>',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-success m-1',
                        },
                        confirmButtonText: 'Ok',
                        preConfirm: e => {
                            $(".swal2-confirm").attr('disabled', 'disabled');
                        }
                    }).then(result => {
                        if (result.value) {
                            window.location.reload();
                        }
                    });
                }
            }
        } catch { }
    });


    window.AddbusinessDays = function (date, businessDays = 10) {
        var tmp = new Date(date);
        while (businessDays > 0) {
            tmp = tmp.fp_incr(1);
            if (!([0, 6].includes(tmp.getDay()))) {
                businessDays--;
            }
        }
        return tmp;
    }


    window.appDataTable = function (params) {

        // params = {_this: null, DomElementTable: null, options: {method: null, route: null, columns: [], fnDrawCallback: [], columnDefs: [], parameters: null}}

        let self = params._this;

        // Override a few default classes
        jQuery.extend(jQuery.fn.dataTable.ext.classes, {
            sWrapper: "dataTables_wrapper dt-bootstrap4",
            sFilterInput: "form-control form-control-sm",
            sLengthSelect: "form-control form-control-sm"
        });


        params.objTable = params.DomElementTable.DataTable({

            language: {
                url: `${process.env.MIX_PUBLIC_URL}/datatable/language`
            },
            searching: params.dataTableOptions.searching ?? false,
            responsive: true,
            autoWidth: false,
            processing: true,
            serverSide: true,
            lengthChange: params.dataTableOptions.lengthChange ?? true,
            info: params.dataTableOptions.info ?? true,
            deferLoading: params.dataTableOptions.deferLoading ?? null,
            select: {
                style: params.dataTableOptions.select?.style ?? 'single',
                info: params.dataTableOptions.select?.info ?? false,
                blurable: params.dataTableOptions.select?.blurable ?? false,
                selector: params.dataTableOptions.select?.selector ?? 'td:not(:last-child)',
            },
            order: params.dataTableOptions.order,
            ajax: {
                type: params.dataTableOptions.method,
                url: params.dataTableOptions.route,
                data: params.dataTableOptions.data,
            },
            columns: params.dataTableOptions.columns,
            fnDrawCallback: params.dataTableOptions.fnDrawCallback,
            columnDefs: params.dataTableOptions.columnDefs,
        });

        if (!params.dataTableOptions.searching) {

            let searchFiltersDom = params.searchOptions?.searchFiltersDom || $('.search-filters');
            params.dataTableOptions.columns.forEach(function (column, index) {
                if (column.searchName) {
                    let input = '';
                    if (column.type && column.type.toLowerCase() === 'select') {
                        input = `<div class="form-group mr-md-2 w-100"><select class="js-select2 form-control dt_search_field mr-md-2" id="${column.searchName}" name="${column.searchName}" style="width: 100%;" ></select></div>`;
                        searchFiltersDom.append(input);
                        searchFiltersDom.find('#' + column.searchName).empty();
                        searchFiltersDom.find('#' + column.searchName).select2({
                            placeholder: column.title,
                            allowClear: true,
                            width: 'resolve',
                            ajax: {
                                url: `${process.env.MIX_API_URL}/${column.route}?column=${column.searchName}`,
                                contentType: 'application/json',
                                dataType: 'json',
                                delay: 250,
                                data: function (params) {
                                    return {
                                        load: true,
                                        name: $.trim(params.term), // search term
                                        page: params.page || 1
                                    };
                                },
                                success: function (response) {
                                },
                                processResults: function (data, params) {
                                    params.page = params.page || 1;
                                    let results = data.data.map(function (item) {
                                        item.text = item[column.columnName];
                                        return item;
                                    });
                                    return {
                                        results: results,
                                        pagination: {
                                            more: params.page !== data.meta.last_page
                                        }
                                    };
                                },
                                cache: true
                            },
                            // minimumInputLength: 3,
                            templateResult: function (data, x) {
                                if (data.loading) {
                                    return data.text;
                                }
                                return data[column.columnName];
                            }
                            // templateResult: self.formatResult,
                            // templateSelection: self.formatResult
                        });
                    } else if (column.type === 'date') {
                        input = `<div class="form-group mr-md-2 w-100"><input type="text" class="form-control dt_search_field js-flatpickr bg-white" name="${column.searchName}" placeholder="${column.title.toUpperCase()}" /></div>`;
                        searchFiltersDom.append(input);
                        One.helpers(['flatpickr']);
                    }
                    else {
                        let title = (column.title ?? '').toUpperCase();
                        input = `<div class="form-group mr-md-2 w-100"><input type="'text'" class="form-control dt_search_field mr-md-2" name="${column.searchName}" placeholder="${title}" /></div>`;
                        searchFiltersDom.append(input);
                    }
                }
            });

            params.objTable.on('preXhr', function (e, settings, data) {
                searchFiltersDom.find('.dt_search_field').each(function (index, item) {
                    let itemInput = $(item);
                    if (itemInput[0].nodeName === 'SELECT') {
                        let selectData = $('#' + itemInput.attr('id')).select2('data');
                        if (selectData && selectData.length) {
                            data[itemInput.attr('name')] = selectData[0].text;
                        }
                    } else if (['checkbox', 'radio'].includes(itemInput.attr('type'))) {
                        data[itemInput.attr('name')] = itemInput.prop('checked') ? 1 : 0;
                    } else if (itemInput.val() !== '') {
                        data[itemInput.attr('name')] = itemInput.val();
                    }

                    if (itemInput.attr('name') === 'backupZone') {
                        $('#exportable-zone').val(itemInput.val());
                    }
                });
            });

            let submitSearchDom = params.searchOptions?.submitSearchDom || $('.submit-search');
            submitSearchDom.off('click');
            searchFiltersDom.keypress(function (e) {
                if (e.which === 13) { //enter key
                    submitSearchDom.click();
                }
            });
            submitSearchDom.on('click', function (e) {
                // Reload datatable ajax
                params.objTable.draw()
            });
            let clearSearchDom = params.searchOptions?.clearSearchDom || $('.clear-search');
            clearSearchDom.off('click');
            clearSearchDom.on('click', function (e) {
                searchFiltersDom.find('.dt_search_field').each(function (index, item) {
                    let itemInput = $(item);
                    if (itemInput[0].nodeName === 'SELECT') {
                        $('#' + itemInput.attr('id')).val('').trigger('change');
                    } else {
                        itemInput.val('');
                    }
                });
                params.objTable.ajax.reload(null, false);
            });
        }

        return params.objTable;

    }

    window.exportSelect2 = function (p) {
        $.ajax({
            method: 'GET',
            url: p.route,
            data: {
                type: p.type,
                load: true,
            },
            success: function (data, type, xhr) {
                let columns = data.columns;
                if (columns.length) {
                    p.element.empty();
                    columns.forEach(function (column, i) {
                        let _col_checkbox = `<div class="custom-control custom-checkbox custom-control-danger mb-1 exportable_column_wrapper ${column.hidden ? 'hidden' : ''}">
                                                <input type="checkbox" class="custom-control-input exportable_column" id="exportable_columns_${column.key}" 
                                                        name="exportable_columns[]" value="${column.key}" data-value="${column.value}"  ${column.hidden ? 'cheked' : ''}>
                                                <label class="custom-control-label" for="exportable_columns_${column.key}">${column.name}</label>
                                            </div>`;
                        p.element.append(_col_checkbox);
                    })
                }
                $('#exportable_columns_all').off('change').on('change', function () {
                    let all_checked = $(this).prop('checked');
                    $('.exportable_column').prop('checked', all_checked);
                })
            },
            error: function (err) {
                console.log(err);
            }
        });
    }

    window.downloadJournalDeContacts = function (obj) {
        $.ajax({
            method: 'post',
            url: obj.url,
            data: obj.data,
            xhrFields: {
                responseType: 'blob'
            },
            success: function (data, type, xhr) {
                var disposition = xhr.getResponseHeader('content-disposition');
                var matches = /"([^"]*)"/.exec(disposition);
                var filename = (matches != null && matches[1] ? matches[1] : document.title.replace(' ', '_').concat('_', moment(new Date(), 'YYYY_MM_DD _hh_mm_ss'), '.csv'));
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = filename;
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            },
            error: function (err) {
                One.helpers('notify', {
                    type: 'danger',
                    icon: 'fas fa-edit mr-1',
                    message: 'Erreur Serveur',
                    z_index: 2000
                });
            }
        });
    };

    //region backup param
    // $(document).on('click', '#right-menu-btn', function () {
    //     $.ajax({
    //         url: `${process.env.MIX_PUBLIC_URL}/api/v1/parametres-app/get-value-by-slug/backup-off-on`,
    //         method: 'GET',
    //         success: function (response) {
    //             if (response && response.value && response.value === '0') {
    //                 $('#backup-system-on-off').prop('checked', false);
    //             }
    //         }
    //     });
    // });
    // $(document).on('change', '#backup-system-on-off', function () {
    //     let data = {
    //         slug: 'backup-off-on',
    //         value: $('#backup-system-on-off').prop('checked') ? 1 : 0
    //     };
    //     $.ajax({
    //         url: `${process.env.MIX_PUBLIC_URL}/api/v1/users/updateBackUpOnOff`,
    //         method: 'POST',
    //         data: data,
    //         success: function (response) {
    //             let message = 'Validé avec succès';
    //             if (response.message) {
    //                 message = response.message;
    //             }
    //             One.helpers('notify', {
    //                 type: 'success',
    //                 icon: 'fas fa-check-circle mr-1',
    //                 message: message
    //             });
    //             if (!['admin', 'system'].includes(window.user_role)) {
    //                 window.location.reload();
    //             }
    //         },
    //         error: function (jqXHR, textStatus, errorThrown) {
    //             if (jqXHR.responseJSON && jqXHR.responseJSON.errors) {
    //                 let responseErrors = jqXHR.responseJSON.errors;
    //                 for (let error in responseErrors) {
    //                     let errors = responseErrors[error];
    //                     if (Array.isArray(errors)) {
    //                         errors.forEach(function (errorMsg) {
    //                             One.helpers('notify', {
    //                                 type: 'danger',
    //                                 icon: 'fas fa-times-circle mr-1',
    //                                 message: errorMsg,
    //                                 z_index: 1060,
    //                                 delay: 10000,
    //                                 timer: 2000
    //                             });
    //                         });
    //                     } else {
    //                         One.helpers('notify', {
    //                             type: 'danger',
    //                             icon: 'fas fa-times-circle mr-1',
    //                             message: 'Validation non aboutie',
    //                             z_index: 1060,
    //                             delay: 10000,
    //                             timer: 2000
    //                         });
    //                     }
    //                 }
    //             } else {
    //                 One.helpers('notify', {
    //                     type: 'danger',
    //                     icon: 'fas fa-times-circle mr-1',
    //                     message: 'Validation non aboutie',
    //                     z_index: 1060
    //                 });
    //             }
    //         }
    //     });
    // });
    //endregion

    window.removeClickOnNonImagesFiles = function () {
        const links = document.querySelectorAll('p.name > a[download]');
        const extensions = ['xls', 'xlsx', 'doc', 'docx', 'pdf'];
        links.forEach((link) => {
            const ext = link.href.substr(link.href.lastIndexOf('.') + 1);
            if (extensions.includes(ext)) {
                const newItem = document.createElement('span');
                newItem.innerText = link.innerText;
                link.parentElement.replaceChild(newItem, link);
            }
        });
        const previews = document.querySelectorAll('span.preview > a[download]');
        previews.forEach((preview) => {
            const ext = preview.href.substr(preview.href.lastIndexOf('.') + 1);
            if (extensions.includes(ext)) {
                const img = $(preview).find('img').attr('src', process.env.MIX_PUBLIC_URL + '/images/document.png');
                $(preview).parent().html(img);
            }
        });
    }

    window.donneesRaccordement = function (data) {
        let tableDonneesRaccordement = `<tr>
                <th colspan="2" class="text-center">Informations Générales</th>
            </tr>`;
        if (data.nro_name) {
            tableDonneesRaccordement += `<tr>
                    <th>Nro</th>
                    <td>${data.nro_name}</td>
                </tr>`;
        }
        if (data.typo_pon) {
            if (data.modele_name) {
                tableDonneesRaccordement += `<tr>
                        <th>Actifs à installer</th>
                        <td>${data.modele_name}</td>
                    </tr>`;
            }
            tableDonneesRaccordement += `<tr>
                    <th>Type d'intervention</th>
                    <td>${data.typo_pon}</td>
                </tr>
                <tr>
                    <th>Type de pose</th>
                    <td>${data.type_pose}</td>
                </tr>
                <tr>
                    <th>Duree intervention</th>
                    <td>${data.duree_intervention}</td>
                </tr>`;
            if (data.type_intervention) {
                tableDonneesRaccordement += `<tr>
                        <th>Type de Cheminement PBO PTO</th>
                        <td>${data.type_intervention}</td>
                    </tr>`;
            }
            if (data.nature_intervention) {
                tableDonneesRaccordement += `<tr>
                        <th>Type de PBO</th>
                        <td>${data.nature_intervention}</td>
                    </tr>`;
            }
        }

        tableDonneesRaccordement += `<tr>
                <th colspan="2" class="text-center">Informations NRO</th>
            </tr>`;
        $.each(data.infoRaccordement.Informations_NRO, function (key, value) {
            tableDonneesRaccordement += `<tr>
                    <th>${key}</th>
                    <td>${value ? value : ''}</td>
                </tr>`;
        });

        tableDonneesRaccordement += `<tr>
                <th colspan="2" class="text-center">Données de Brassage au PM</th>
            </tr>`;
        $.each(data.infoRaccordement.Brassage_PM, function (key, value) {
            tableDonneesRaccordement += `<tr>
                    <th>${key}</th>
                    <td>${value ? value : ''}</td>
                </tr>`;
        });

        tableDonneesRaccordement += `<tr>
                <th colspan="2" class="text-center">Données de Raccordement au PBO</th>
            </tr>`;
        let coord_x = null;
        $.each(data.infoRaccordement, function (key, value) {
            tableDonneesRaccordement += `<tr>
                <th>${key}</th>
                <td>${value ? value : ''}</td>
            </tr>`;
            if (['Coordonnées PBO x', 'Coordonnées PBO y'].includes(key)) {
                if (value && value !== 'N/D (WS)') {
                    value = value.toString().replaceAll(',', '.').replaceAll(' ', '');
                    if (key === 'Coordonnées PBO x') {
                        coord_x = value;
                    }
                    else if (key === 'Coordonnées PBO y') {
                        const coord = Math.abs(parseFloat(value)) > 180
                            ? lambert93toWGPS(coord_x, value)
                            : { longitude: coord_x, latitude: value };
                        const btn = `<a href="https://maps.google.com/maps?daddr=${coord.latitude},${coord.longitude}" 
                                        target="_blank" class="btn btn-primary btn-block">
                                            <i class="fas fa-map-marker-alt mr-1"></i>
                                            <span>Google Maps</span>
                                    </a>`;
                        tableDonneesRaccordement += `<tr>
                                <th>Localisation PBO: </th>
                                <td>${btn}</td>
                            </tr>`;
                        coord_x = null;
                    }
                }
            }
        });

        let etatsHstorique = `<tr>
                <td colspan="4" class="text-center">Aucune donnée disponible dans le tableau</td>
            </tr>`;
        if (data.infoRaccordement.etatsHistorique.length > 0) {
            etatsHstorique = '';
            $.each(data.infoRaccordement.etatsHistorique, function (index, item) {
                etatsHstorique += `<tr>
                        <td>${item.type}</td>
                        <td>${item.code}</td>
                        <td>${item.etat}</td>
                        <td>${item.date}</td>
                    </tr>`;
            });
        }

        tableDonneesRaccordement += `<tr>
                <th>Historique d'états</th>
                <td>
                    <button type="button" id="btn-toggle-historique-etat"
                    class="btn btn-primary btn-block">
                        <i class="fa fa-fw fa-angle-down mr-1"></i>
                        <span>Afficher l'historique</span>
                    </button>
                </td>
            </tr>
            <tr id="tr-historique-etat" class="d-none">
                <td colspan="2">
                    <div id="block-historique-etat" class="block">
                        <div class="block-content">
                            <div class="table-responsive">
                                <table id="table-historique-etat" class="table table-striped table-vcenter w-100">
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Code</th>
                                        <th>Etat</th>
                                        <th>Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>${etatsHstorique}</tbody>
                                </table>
                            </div>
                    </div>
                    </div>
                </td>
            </tr>`;

        if (data.raccmapgen) {
            tableDonneesRaccordement += `<tr>
                    <th>Parcours Identifié</th>
                    <td>
                        <a target="_blank" href="${process.env.MIX_PUBLIC_URL}/storage/raccmapgen/${data.raccmapgen}" class="btn btn-primary btn-block">
                            <i class="fas fa-download mr-1"></i>
                            <span>Télécharger</span>
                        </a>
                    </td>
                </tr>`;
        }

        return tableDonneesRaccordement;
    };

    window.ajax = function (object, params = null) {
        let self = this;
        $.ajax({
            url: object.route,
            method: object.method,
            data: object.data,
            dataType: object.dataType,
            processData: typeof object.processData !== 'undefined' ? object.processData : true,
            contentType: typeof object.contentType !== 'undefined' ? object.contentType : 'application/x-www-form-urlencoded; charset=UTF-8',
            success: function (response) {
                $('body').find('.loader-wrapper').remove();
                if (typeof object.success !== "undefined") {
                    object.success(response, params);
                } else {
                    successResponseHandler(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $('body').find('.loader-wrapper').remove();
                if (typeof object.error !== "undefined") {
                    object.error(jqXHR);
                } else {
                    errorResponseHandler(jqXHR);
                }
            }
        });
    }

    window.successResponseHandler = function (response) {
        let message = 'Validé avec succès';
        if (response.data && response.data.message) {
            message = response.data.message;
        }
        One.helpers('notify', {
            type: 'success',
            icon: 'fas fa-check-circle mr-1',
            message: message,
            z_index: 1060,
        });
    }

    window.errorResponseHandler = function (jqXHR) {
        let message = "Une erreur est survenue. Veuillez recharger la page!";
        let response = jqXHR.responseJSON;
        if (response && response.data && response.data.message) {
            message = response.data.message;
        }
        if (response) {
            let responseErrors = response.errors;
            if (responseErrors) {
                if (!Array.isArray(responseErrors)) {
                    responseErrors = Object.values(responseErrors);
                }
                responseErrors.forEach(function (error) {
                    let errors = responseErrors[error] ?? error;
                    if (Array.isArray(errors)) {
                        errors.forEach(function (errorMsg) {
                            One.helpers('notify', {
                                type: 'danger',
                                icon: 'fas fa-times-circle mr-1',
                                message: errorMsg,
                                z_index: 1060,
                                delay: 10000,
                                timer: 2000
                            });
                        });
                    } else {
                        One.helpers('notify', {
                            type: 'danger',
                            icon: 'fas fa-times-circle mr-1',
                            message: message,
                            z_index: 1060,
                            delay: 10000,
                            timer: 2000
                        });
                    }
                });
            } else {
                if (jqXHR.status === 403 && response.message) {
                    message = response.message;
                }
                One.helpers('notify', {
                    type: 'danger',
                    icon: 'fas fa-times-circle mr-1',
                    message: message,
                    z_index: 1060,
                });
            }
            return;
        }
        One.helpers('notify', {
            type: 'danger',
            icon: 'fas fa-times-circle mr-1',
            message: message,
            z_index: 1060,
        });
    }


    window.raccoData = function (commandeProSelected, tableID, commandes_pro_id = null, intervention_id = null, modalSelector = null) {
        $.ajax({
            url: `${process.env.MIX_API_URL}/ws/info-raccordements`,
            method: 'POST',
            data: {
                intervention_id: intervention_id || commandeProSelected.intervention_id,
                commande_pro_id: commandes_pro_id || commandeProSelected.commandes_pro_id,
            },
            success: (response) => {
                setColor(response.color);
                if (modalSelector) {
                    $(modalSelector).modal('show');
                }
                commandeProSelected.infoRaccordement = response.infoRaccordement;

                $(`${tableID} tbody`).empty();

                $(`${tableID} tbody`).append(`<tr>
                            <th colspan="2" class="text-center">Informations Générales</th>
                        </tr>`);

                if (commandeProSelected.nro_name) {
                    $(`${tableID} tbody`).append(`<tr>
                                <th>Nro</th>
                                <td>${commandeProSelected.nro_name}</td>
                            </tr>`);
                }
                if (commandeProSelected.typo_pon) {
                    if (commandeProSelected.modele_name) {
                        $(`${tableID} tbody`).append(`<tr>
                                    <th>Actifs à installer</th>
                                    <td>${commandeProSelected.modele_name}</td>
                                </tr>`);
                    }
                    $(`${tableID} tbody`).append(`<tr>
                                    <th>Type d'intervention</th>
                                    <td>${commandeProSelected.typo_pon}</td>
                                </tr>
                                <tr>
                                    <th>Type de pose</th>
                                    <td>${commandeProSelected.type_pose}</td>
                                </tr>
                                <tr>
                                    <th>Duree intervention</th>
                                    <td>${commandeProSelected.duree_intervention}</td>
                                </tr>`);
                    if (commandeProSelected.type_intervention) {
                        $(`${tableID} tbody`).append(`<tr>
                                    <th>Type de Cheminement PBO PTO</th>
                                    <td>${commandeProSelected.type_intervention}</td>
                                </tr>`);
                    }
                    if (commandeProSelected.nature_intervention) {
                        $(`${tableID} tbody`).append(`<tr>
                                    <th>Type de PBO</th>
                                    <td>${commandeProSelected.nature_intervention}</td>
                                </tr>`);
                    }
                }

                $(`${tableID} tbody`).append(`<tr>
                            <th colspan="2" class="text-center">Informations NRO</th>
                        </tr>`);
                $.each(commandeProSelected.infoRaccordement.Informations_NRO, function (key, value) {
                    $(`${tableID} tbody`).append(`<tr>
                                <th>${key}</th>
                                <td>${value ? value : ''}</td>
                            </tr>`);
                });
                $(`${tableID} tbody`).append(`<tr>
                            <th colspan="2" class="text-center">Données de Brassage au PM</th>
                        </tr>`);
                $.each(commandeProSelected.infoRaccordement.Brassage_PM, function (key, value) {
                    $(`${tableID} tbody`).append(`<tr>
                                <th>${key}</th>
                                <td>${value ? value : ''}</td>
                            </tr>`);
                });

                $(`${tableID} tbody`).append(`<tr>
                            <th colspan="2" class="text-center">Données de Raccordement au PBO</th>
                        </tr>`);
                let coord_x = null;
                $.each(commandeProSelected.infoRaccordement.Raccordement_PBO, function (key, value) {
                    $(`${tableID} tbody`).append(`<tr>
                        <th>${key}</th>
                        <td>${value ? value : ''}</td>
                    </tr>`);
                    if (['Coordonnées PBO x', 'Coordonnées PBO y'].includes(key)) {
                        if (value && value !== 'N/D (WS)') {
                            value = value.toString().replaceAll(',', '.').replaceAll(' ', '');
                            if (key === 'Coordonnées PBO x') {
                                coord_x = value;
                            }
                            else if (key === 'Coordonnées PBO y') {
                                const coord = Math.abs(parseFloat(value)) > 180
                                    ? lambert93toWGPS(coord_x, value)
                                    : { longitude: coord_x, latitude: value };
                                const btn = `<a href="https://maps.google.com/maps?daddr=${coord.latitude},${coord.longitude}" 
                                                target="_blank" class="btn btn-primary btn-block">
                                                    <i class="fas fa-map-marker-alt mr-1"></i>
                                                    <span>Google Maps</span>
                                            </a>`;
                                $(`${tableID} tbody`).append(`<tr>
                                        <th>Localisation PBO: </th>
                                        <td>${btn}</td>
                                    </tr>`);
                                coord_x = null;
                            }
                        }
                    }
                });

                let etatsHstorique = `<tr>
                            <td colspan="4" class="text-center">Aucune donnée disponible dans le tableau</td>
                        </tr>`;
                if (commandeProSelected.infoRaccordement.etatsHistorique.length > 0) {
                    etatsHstorique = '';
                    $.each(commandeProSelected.infoRaccordement.etatsHistorique, function (index, item) {
                        etatsHstorique += `<tr>
                                    <td>${item.type}</td>
                                    <td>${item.code}</td>
                                    <td>${item.etat}</td>
                                    <td>${item.date}</td>
                                </tr>`;
                    });
                }

                $(`${tableID}`).append(`<tr>
                            <th>Historique d'états</th>
                            <td>
                                <button type="button" id="btn-toggle-historique-etat"
                                class="btn btn-primary btn-block">
                                    <i class="fa fa-fw fa-angle-down mr-1"></i>
                                    <span>Afficher l'historique</span>
                                </button>
                            </td>
                        </tr>
                        <tr id="tr-historique-etat" class="d-none">
                            <td colspan="2">
                                <div id="block-historique-etat" class="block">
                                    <div class="block-content">
                                        <button type="button" id="btn-get-historique-etat"
                                                class="btn btn-primary btn-block">
                                            <i class="fas fa-sync mr-1"></i>
                                            <span>Rafraichir l'historique</span>
                                        </button>
                                        <div class="table-responsive">
                                            <table id="table-historique-etat" class="table table-striped table-vcenter w-100">
                                                <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Code</th>
                                                    <th>Etat</th>
                                                    <th>Date</th>
                                                </tr>
                                                </thead>
                                                <tbody>${etatsHstorique}</tbody>
                                            </table>
                                        </div>
                                </div>
                                </div>
                            </td>
                        </tr>`);

                // $(`${tableID}').append(`<tr>
                //     <th colspan="2" class="text-center">Fichiers</th>
                // </tr>`);
                if (commandeProSelected.raccmapgen) {
                    $(`${tableID}`).append(`<tr>
                                <th>Parcours Identifié</th>
                                <td>
                                    <a target="_blank" href="${process.env.MIX_PUBLIC_URL}/storage/raccmapgen/${commandeProSelected.raccmapgen}" class="btn btn-primary btn-block">
                                        <i class="fas fa-download mr-1"></i>
                                        <span>Télécharger</span>
                                    </a>
                                </td>
                            </tr>`);
                }
            },
        });


        $(document).off('click', '#btn-toggle-historique-etat')
            .on('click', '#btn-toggle-historique-etat', function () {
                let trHistoriqueEtat = $('#tr-historique-etat');
                if (!trHistoriqueEtat.hasClass('d-none')) {
                    trHistoriqueEtat.addClass('d-none');
                    $(this).find('span').text('Afficher l\'historique');
                    $(this).find('i').removeClass('fa-angle-up').addClass('fa-angle-down');
                    return;
                }
                trHistoriqueEtat.removeClass('d-none');
                $(this).find('span').text('Masquer l\'historique');
                $(this).find('i').removeClass('fa-angle-down').addClass('fa-angle-up');
            });

        $(document).off('click', '#btn-get-historique-etat')
            .on('click', '#btn-get-historique-etat', function () {
                ajax({
                    route: `${process.env.MIX_API_URL}/ws/etats-historique/${commandes_pro_id || commandeProSelected.commandes_pro_id}`,
                    method: 'get',
                    success: (data) => {
                        let bodyTableEtatsHistorique = $('#table-historique-etat tbody');
                        bodyTableEtatsHistorique.empty();
                        if (data.length > 0) {
                            $.each(data, function (index, item) {
                                bodyTableEtatsHistorique.append(`<tr>
                                    <td>${item.type}</td>
                                    <td>${item.code}</td>
                                    <td>${item.etat}</td>
                                    <td>${item.date}</td>
                                </tr>`);
                            });
                        } else {
                            bodyTableEtatsHistorique.append(`<tr>
                                <td colspan="4" class="text-center">Aucune donnée disponible dans le tableau</td>
                            </tr>`);
                        }
                    }
                });
            });
    }

    window.setColor = function (color) {
        const rgba = hexToRGBA(color, 0.25);

        $('#style-dynamic').remove();

        $('head').append(`<style id="style-dynamic" type="text/css">
                .style-dynamic .bg-primary,
                .style-dynamic .select2-results__option--highlighted[aria-selected],
                .style-dynamic .select2-container--default .select2-selection--multiple .select2-selection__choice {
                    background-color: ${color} !important;
                }

                .style-dynamic .btn-primary {
                    background-color: transparent !important;
                    color: ${color} !important;
                    border-color: ${color} !important;
                }

                .style-dynamic .btn-primary:hover,
                .style-dynamic .btn-primary.active, .style-dynamic .btn-primary:active,
                .style-dynamic .btn-primary.disabled, .style-dynamic .btn-primary:disabled,
                .style-dynamic .custom-control-input:checked ~ .btn-primary {
                    background-color: ${color} !important;
                    color: #ffffff !important;
                }

                .style-dynamic .btn-primary:focus {
                    box-shadow: 0 0 0 0.2rem ${rgba} !important;
                }

                .style-dynamic .name a, .style-dynamic .name span {
                    color: ${color} !important;
                }

                .style-dynamic .nav-link.active,
                .style-dynamic .nav-link:hover,
                .style-dynamic .nav-link:focus {
                    color: ${color} !important;
                    box-shadow: inset 0 -2px ${color} !important;
                }

                .style-dynamic .custom-control-label::before {
                    background-color: #ebebeb !important;
                    border-color: ${color} !important;
                }

                .style-dynamic .custom-control-input:checked ~ .custom-control-label::before,
                .style-dynamic .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
                    border-color: ${color} !important;
                    background-color: ${color} !important;
                }

                .style-dynamic .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
                    border-color: ${color} !important;
                }

                .style-dynamic .select2-container--default.select2-container--focus .select2-selection--multiple,
                .style-dynamic .select2-container--default.select2-container--focus .select2-selection--single,
                .style-dynamic .select2-container--default.select2-container--open .select2-selection--multiple,
                .style-dynamic .select2-container--default.select2-container--open .select2-selection--single,
                .style-dynamic .select2-search__field:focus,
                .style-dynamic .form-control:focus,
                .style-dynamic .custom-file-input:focus ~ .custom-file-label {
                    border-color: ${color} !important;
                    box-shadow: 0 0 0 0.2rem ${rgba} !important;
                }
            </style>`);

        $(document).on('select2:open', 'select', function (e) {
            if ($(this).closest('.style-dynamic').length > 0) {
                $('.select2-dropdown').addClass('style-dynamic');
            }
        });
    };

    window.hexToRGBA = function (hex, opacity) {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    window.demandesReprov = function (demandesReprovData) {
        let tableDonneesRaccordement = `
        <tr>
            <th colspan="2" class="text-center">Demandes de reprov</th>
        </tr>`;

        if (demandesReprovData && demandesReprovData.length > 0) {
            tableDonneesRaccordement += `
            <tr>
                <td colspan="2" class="border-top-0 pt-0">
                    <table class="table table-striped table-vcenter w-100">
                        <thead>
                            <tr>
                                <th>Numéro de décharge</th>
                                <th>Numéro de PTO</th>
                                <th>Date de création</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${demandesReprovData.map(item => `
                                <tr>
                                    <td>${item.numero_decharge || ''}</td>
                                    <td>${item.numero_pto || ''}</td>
                                    <td>${item.created_at || ''}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </td>
            </tr>`;
        } else {
            tableDonneesRaccordement += `
            <tr>
                <td colspan="2" class="text-center">Aucun numéro de décharge disponible</td>
            </tr>`;
        }

        return tableDonneesRaccordement;
    }
})(jQuery);
