const loader = `<div class="loader-wrapper">
                    <div class="loader-backdrop"></div>
                    <div class="loader-container">
                        <div class="loader"></div>
                    </div>
                </div>`;

const notifyAlert = (type, message, icon = 'check-circle', z_index = 1060, timer = 5000) => {
    One.helpers('notify', {
        type: type,
        icon: 'fa fa-' + icon + ' mr-1',
        message: message,
        z_index: z_index,
        timer: timer
    });
};

const formValidation = ({form, ignore, rules, messages}) => (
    form.validate({
        ignore: ignore,
        rules: rules,
        messages: messages
    })
);

const displayServerErrors = (data, selector) => {
    $(selector).removeAttr('hidden');
    $.each(data.errors, function (i, error) {
        $(selector + ' ul').append('<li>' + error[0] + '</li>');
    });
};

const modalAlert = (selector, msg, timeout) => {
    $(selector).empty();
    $(selector).removeClass("alert-success");
    $(selector).addClass("alert-danger");
    $(selector).append('<label for="name">' + msg + '</label>');
    $(selector).show();
    setTimeout(function () {
        $(selector).hide();
    }, timeout);
};

const isBlank = el => (!$.trim(el.val()));

const htmlDecode = (input) => {
    let e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

const findObjectById = (array, id) => array.find(x => x.id == id);

const filterArray = (array, id) => array.filter(el => el.id != id);

const getDataWithAjax = ({method, url, data = null, success, error}) => {
    $('body').append(loader);
    $.ajax({
        method: method,
        url: url,
        data: data,
        success: success,
        error: error
    });
};

const initSelect = ({selector, placeholder, route, template = null}) => {
    const formatResult = result => (result.name || result.text);
    selector.empty();
    selector.select2({
        placeholder: placeholder,
        width: '100%',
        allowClear: true,
        ajax: {
            url: route,
            contentType: 'application/json',
            dataType: 'json',
            data: function (params) {
                return {
                    load: true,
                    name: params.term, // search term
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;
                return {
                    results: data.data,
                    pagination: {
                        more: params.page !== data.meta.last_page
                    }
                };
            },
            cache: true
        },
        templateResult: template ?? formatResult,
        templateSelection: template ?? formatResult
    });
};

const selectDefaultValue = ({data, selector}) => {
    selector.append(new Option(data.value, data.id, true, true)).trigger('change');
    selector.trigger({
        type: 'select2:select',
        params: {
            data: data
        }
    });
};

const euroFormat = number => (
    new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    }).format(number)
);

Math.tanh = Math.tanh || function(x) {
    if(x === Infinity) {
        return 1;
    } else if(x === -Infinity) {
        return -1;
    } else {
        return (Math.exp(x) - Math.exp(-x)) / (Math.exp(x) + Math.exp(-x));
    }
};

Math.atanh = Math.atanh || function(x) {
    return Math.log((1+x)/(1-x)) / 2;
};

const lambert93toWGPS = (lambertE, lambertN) => {

    const constantes = {
        GRS80E: 0.081819191042816,
        LONG_0: 3,
        XS: 700000,
        YS: 12655612.0499,
        n: 0.7256077650532670,
        C: 11754255.4261
    };

    let delX = lambertE - constantes.XS;
    let delY = lambertN - constantes.YS;
    let gamma = Math.atan(-delX / delY);
    let R = Math.sqrt(delX * delX + delY * delY);
    let latiso = Math.log(constantes.C / R) / constantes.n;
    let sinPhiit0 = Math.tanh(latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * Math.sin(1)));
    let sinPhiit1 = Math.tanh(latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit0));
    let sinPhiit2 = Math.tanh(latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit1));
    let sinPhiit3 = Math.tanh(latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit2));
    let sinPhiit4 = Math.tanh(latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit3));
    let sinPhiit5 = Math.tanh(latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit4));
    let sinPhiit6 = Math.tanh(latiso + constantes.GRS80E * Math.atanh(constantes.GRS80E * sinPhiit5));

    let longRad = Math.asin(sinPhiit6);
    let latRad = gamma / constantes.n + constantes.LONG_0 / 180 * Math.PI;

    let longitude = latRad / Math.PI * 180;
    let latitude = longRad / Math.PI * 180;

    return {longitude: longitude, latitude: latitude};
};

export {
    notifyAlert,
    getDataWithAjax,
    formValidation,
    findObjectById,
    filterArray,
    htmlDecode,
    displayServerErrors,
    modalAlert,
    initSelect,
    selectDefaultValue,
    euroFormat,
    lambert93toWGPS
};